$primary: #38573c;
$lightPrimary: #8ea986;
$lightPrimary2: #d6e0d3;
$lightPrimary2light: rgba(214, 224, 211, 0.4);
$lightPrimary3: #d6e0d366;
$borderFocus: #373737;
$boxshadow: #00000029;
$light: #f6f6f6;
$light1: #f9f9f9;
$dashed: #dbdbdb;
$gray: #c8c8c8;
$gray2: #ededed;
$gray3: #808080;
$gray4: #929292;
$gray5: #a4a4a4;
$gray6: #494949;
$lightGray: #9a9ea6;
$lightcyan: #f8fafa;
$dark: #1b1919;
$dark2: #151414;
$errorBg: #fdf0f1;
$error: #e0293b;
$white: #ffffff;
$link: #1e88e5;
$text1: #3e3b3c;
$grid-gutter-width: 2rem;
$btn-font-family: "Futura Medium Bt";
$btn-padding-y: 13px;
$btn-padding-x: 32px;
$btn-font-size: 20px;
$btn-border-radius: 0;
$btn-line-height: 28px;
$btn-padding-y-sm: 3px;
$btn-padding-x-sm: 23px;
$btn-border-radius-sm: 0;
