/* ==========================================================================
   Responsive
   ========================================================================== */
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  // .container {
  //   max-width: 1440px;
  // }
}

@media only screen and (max-width: 1600px) {
  .hero-area h2 {
    font-size: 60px;
    margin-bottom: 18px;
    line-height: 80px;
  }

  .hero-area h5 {
    font-size: 22px;
    margin-bottom: 35px;
    line-height: 40px;
  }

  .greena-card-details h2 {
    margin-bottom: 36px;
    font-size: 38px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .greena-card-details h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .greena-card-details h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1600px) {
  // .container {
  //   max-width: 1140px;
  // }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  h2 {
    font-size: 36px;
    line-height: 44px;
  }

  // .btn {
  //   font-size: 20px;
  //   padding: 10px 30px;
  // }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 16px;
    padding: 10px 22px 8px;
  }

  /*---------------------------------------------------------*/
  /*--------------------- WHY SECTION ---------------------- */
  /*---------------------------------------------------------*/
  .why {
    padding: 180px 0px 0px;
  }

  .why-title {
    margin-bottom: 32px;
  }

  // .why-points {
  //   margin-bottom: 80px;
  // }

  .result-points {
    margin-bottom: 32px;
  }

  .why-single-point {
    padding-right: 12px;
  }

  .why-single-point img {
    height: 50px;
    margin-bottom: 24px;
  }

  .why-single-point h4 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .single-result h4 {
    font-size: 24px;
    bottom: 28px;
  }

  /*---------------------------------------------------------*/
  /*--------------------- HOW SECTION ---------------------- */
  /*---------------------------------------------------------*/
  .how {
    padding: 70px 0px;
  }

  .how-content p {
    margin-bottom: 20px;
  }

  .how-slider .owl-carousel {
    padding: 30px 0px;
  }

  .single-how-content {
    font-size: 16px;
    padding: 0px 16px;
  }

  .single-how-content h4 {
    font-size: 18px;
    line-height: 28px;
    padding: 14px 0px;
  }

  .single-how-rating {
    line-height: 1.8;
    padding: 12px 0px 7px 24px;
  }

  .single-how-rating span {
    line-height: 1.5;
  }

  .single-how-rating:before {
    height: 17px;
    width: 17px;
    left: 0;
    top: 17px;
  }

  .single-how-feachers {
    padding: 14px 0px 10px;
  }

  .single-how-feachers ul li:not(:last-child) {
    margin-bottom: 2px;
  }

  .single-how-location {
    padding: 14px 0px;
  }

  .how-meta {
    padding: 3px 7px 3px 7px;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .single-how-img img {
    // height: 80%;
    // object-fit: contain;
  }

  .how-meta span {
    margin-left: 7px;
    margin-top: 3px;
  }

  .how-slider .owl-nav .owl-next,
  .how-slider .owl-nav .owl-prev {
    height: 42px;
    width: 42px;
    font-size: 18px;
    line-height: 48px;
    margin-left: 10px;
  }

  .how-slider .owl-nav {
    bottom: -45px;
  }

  .how-slider .owl-dots .owl-dot.active {
    background: #38573c;
    border-bottom: 3px solid #38573c;
  }

  .how-slider .owl-dots .owl-dot {
    // background: #ededed;
    width: 100px;
    height: 6px;
    border-bottom: 3px solid #d2d2d2;
  }

  .how-slider .owl-dots {
    bottom: -40px;
  }

  .consequences {
    font-size: 18px;
  }

  .consequences a:after {
    right: -24px;
    height: 18px;
    width: 18px;
    font-size: 18px;
  }

  .how-content p i.bi {
    font-size: 28px;
  }

  /*---------------------------------------------------------*/
  /*------------------ GREENA CARD SECTION ----------------- */
  /*---------------------------------------------------------*/

  .greena-card {
    padding: 90px 0px 10px;
  }

  .greena-card-image img {
    max-width: 74%;
    height: auto;
  }

  .card-feacher-left {
    position: relative;
    background: url(../img/cardbgmid.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80%;
    margin-right: 20px;
  }

  .cardfi2 {
    padding-top: 120px;
  }

  .single-cardf img {
    margin-bottom: 12px;
  }

  .single-cardf h4 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .card-feacher-content h2 {
    margin-bottom: 24px;
  }

  .greena-card-details p small {
    font-size: 12px;
    line-height: 16px;
  }

  .single-cardf p {
    font-size: 16px;
    line-height: 1.5;
  }

  .single-cardf img {
    height: 42px;
    width: auto;
  }

  a.signup-cta {
    padding: 24px 75px 24px 22px;
    font-size: 30px;
    line-height: 36px;
  }

  a.signup-cta span {
    font-size: 14px;
  }

  a.signup-cta:after {
    right: 30px;
    height: 28px;
    width: 28px;
    margin-top: -14px;
    font-size: 28px;
  }

  .card-feacher-left:before {
    height: 65px;
    width: 65px;
    left: 56%;
    top: 7%;
  }

  .card-feacher-left:after {
    height: 45px;
    width: 45px;
    left: 33%;
    bottom: 8%;
  }
}

@media only screen and (max-width: 1200px) {
  .card-feacher-left {
    background-size: 90%;
    margin-right: 20px;
  }

  .hero-area h2 {
    font-size: 54px;
    line-height: 70px;
  }

  .hero-area h5 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .single-result h4 {
    font-size: 20px;
    left: 10px;
  }

  .why-title {
    padding-right: 130px;
  }
}

@media only screen and (max-width: 991px) {
  .hero-area h2 {
    font-size: 44px;
    line-height: 60px;
  }

  .how-slider {
    padding: 75px 0;
  }

  .card-feacher-left {
    background-size: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .hero-area h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .hero-area h5 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  // .btn {
  //   font-size: 16px;
  //   padding: 10px 30px;
  // }

  .why-title {
    margin-bottom: 35px;
  }

  .why-single-point img {
    margin-bottom: 24px;
  }

  .why-single-point h4 {
    font-size: 18px;
    margin-bottom: 13px;
  }

  .why-single-point {
    padding-right: 10px;
  }

  p {
    font-size: 16px;
    line-height: 30px;
  }

  .single-result h4 {
    font-size: 22px;
    left: 15px;
    bottom: 21px;
  }

  .how {
    padding: 60px 0;
  }

  .how-content p {
    margin-bottom: 15px;
  }

  .how-content p i.bi {
    font-size: 28px;
  }

  .how-slider .owl-carousel {
    padding: 0;
  }

  .how-slider .owl-dots .owl-dot.active {
    background: #38573c;
    border-bottom: 3px solid #38573c;
  }

  .how-slider .owl-nav {
    bottom: -70px;
  }

  .single-how-content {
    padding: 0px 15px;
    font-size: 16px;
  }

  .single-how-content h4 {
    font-size: 20px;
    line-height: 30px;
    padding: 15px 0px;
  }

  .single-how-rating {
    padding: 15px 0px 15px 30px;
  }

  .single-how-feachers .list-inline-item {
    display: block;
    font-size: 14px;
  }

  .single-how-location {
    font-size: 16px;
  }

  .how-slider .owl-nav .owl-prev,
  .how-slider .owl-nav .owl-next {
    height: 50px;
    width: 50px;
    line-height: 55px;
  }

  .how-slider .owl-dots {
    bottom: -58px;
  }

  .greena-card {
    padding: 70px 0;
  }

  .greena-card-image img {
    max-width: 90%;
  }

  .card-feacher-left {
    background-size: 90%;
    margin-right: 0px;
  }

  .single-cardf p {
    font-size: 16px;
  }

  a.signup-cta {
    padding: 25px 95px 25px 25px;
    font-size: 34px;
    line-height: 44px;
  }

  .greena-card-details h2 {
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 40px;
  }

  .single-how-rating:before {
    height: 16px;
    width: 16px;
    top: 12px;
  }
}

@media only screen and (max-width: 575.98px) {
  h2 {
    font-size: 24px;
    line-height: 34px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  /*---------------------------------------------------------*/
  /*------------------ GREENA CARD SECTION ------------------*/
  /*---------------------------------------------------------*/
  a.signup-cta {
    padding: 16px 60px 16px 14px;
    background: #8ea986;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  a.signup-cta span {
    color: #e9e9e9;
    font-size: 12px;
    line-height: 16px;
  }

  a.signup-cta:after {
    right: 20px;
    height: 30px;
    width: 30px;
    margin-top: -12px;
    color: #ffffff;
    font-size: 28px;
  }

  a.signup-cta:hover::after {
    color: #ffffff;
  }

  .single-cardf {
    background: #f9f9f9;
    padding: 16px 14px 14px;
    margin-bottom: 16px;
  }

  .single-cardf img {
    height: 30px;
    width: auto;
    margin-bottom: 16px;
  }

  .single-cardf h4 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .single-cardf p {
    font-size: 16px;
    line-height: 24px;
  }

  .greena-card {
    padding: 40px 0px;
  }

  .greena-card-image img {
    max-width: 79%;
  }

  .card-feacher-left {
    background: url(../img/cardbg-mobile.png);
    margin-left: 50px;
    margin-right: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 85%;
    margin-bottom: 45px;
  }

  .card-feacher-content h2.traveller-title {
    max-width: 200px;
  }

  .card-feacher-content h2 {
    margin-bottom: 24px;
  }

  .cardfi2 {
    padding-top: 70px;
  }

  .card-feacher-left:before {
    height: 45px;
    width: 45px;
    left: 53%;
    top: 2%;
  }

  .card-feacher-left:after {
    height: 25px;
    width: 25px;
    left: 36%;
    bottom: 4%;
  }

  .greena-card-details h2 {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
    max-width: 244px;
    margin: 0 auto 20px;
  }

  .greena-card-details p {
    margin-bottom: 20px;
  }

  .greena-card-details p small {
    color: #a2a2a2;
    font-size: 12px;
    line-height: 16px;
  }

  .greena-card-details {
    padding-top: 24px;
  }

  .greena-card-image {
    margin-left: 50px;
    margin-right: 50px;
  }

  .about-greena-card {
    // margin-bottom: 30px;
  }

  /*---------------------------------------------------------*/
  /*--------------------- HOW SECTION -----------------------*/
  /*---------------------------------------------------------*/
  // .how {
  //   padding: 26px 0px;
  // }

  .how-content p {
    margin-bottom: 26px;
  }

  .how-content p i.bi {
    font-size: 24px;
  }

  .how-slider {
    margin-bottom: 16px;
  }

  .how-meta {
    font-size: 12px;
    top: 16px;
    left: 14px;
    padding: 7px 10px 6px 10px;
  }

  .how-meta span {
    margin-top: 1px;
  }

  .how-meta img {
    height: 14px;
    width: auto;
    padding-right: 2px;
  }

  .single-how-content {
    padding: 0px 16px;
    font-size: 14px;
  }

  .single-how-content h4 {
    font-size: 18px;
    line-height: 28px;
    padding: 10px 0px;
  }

  .single-how-feachers {
    padding: 10px 0px;
  }

  .single-how-location {
    padding: 10px 0px;
  }

  .single-how-rating {
    padding: 10px 0px 10px 25px;
  }

  .single-how-rating :before {
    height: 17px;
    width: 17px;
    left: 0;
    top: 12px;
  }

  .how-slider .owl-nav .owl-next,
  .how-slider .owl-nav .owl-prev {
    height: 30px;
    width: 30px;
    margin-left: 12px;
    font-size: 16px;
    line-height: 1;
  }

  .how-slider .owl-dots .owl-dot.active {
    border-bottom: 2px solid #38573c;
  }

  .how-slider .owl-dots .owl-dot {
    width: 40px;
    height: 6px;
    border-bottom: 2px solid #d2d2d2;
  }

  .how-slider .owl-dots {
    bottom: -50px;
  }

  .how-slider .owl-nav {
    bottom: -50px;
    right: 0px;

    @media (max-width: 576px) {
      right: 170px;
    }
  }

  .how-slider {
    padding: 0;
    margin-bottom: 70px;
    &.vendor_slider {
      margin-bottom: 0;
    }
  }

  /*---------------------------------------------------------*/
  /*---------------------- WHY SECTION ----------------------*/
  /*---------------------------------------------------------*/

  .why {
    padding: 150px 0px 34px;
  }

  .single-result h4 {
    left: 8px;
    bottom: 15px;
    font-size: 20px;
    line-height: 22px;
  }

  .why-title {
    margin-bottom: 18px;
    padding-right: 60px;
  }

  // .why-points {
  //   margin-bottom: 24px;
  // }

  .result-points {
    position: relative;
  }

  .result-points .owl-nav {
    right: 130px;
    top: -60px;
  }

  // .why-points .col-lg-3:last-child .why-single-point {
  //   padding-right: 14px;
  // }

  .result-points .owl-nav .owl-next,
  .result-points .owl-nav .owl-prev {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #8ea986;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 3px;
    transition: 0.3s;
    padding: 15px;
    margin: 0 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .result-points .owl-nav .owl-next:hover,
  .result-points .owl-nav .owl-prev:hover {
    background: #8ea986;
    color: #ffffff;
  }

  .consequences {
    font-size: 14px;
    padding-right: 5px;
  }

  .consequences a:after {
    right: -24px;
    height: 18px;
    width: 18px;
    font-size: 14px;
  }

  .result-points {
    margin-bottom: 16px;
    margin-right: -130px;
  }

  .why-single-point {
    padding: 18px 14px 16px;
    border: 1px solid #e9e9e9;
    margin-bottom: 16px;
  }

  .why-single-point img {
    height: 34px;
    width: auto;
    margin-bottom: 20px;
  }

  .why-single-point h4 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  /*---------------------------------------------------------*/
  /*---------------------- Header Section -------------------*/
  /*---------------------------------------------------------*/

  .hero-area h5 {
    font-size: 13px;
  }

  .hero-area {
    padding: 60px 0px 65px;
  }

  .hero-area ul li.list-inline-item:not(:last-child) {
    margin-right: 0.8rem;
  }

  // .hero-area h2 br {
  //     display: none;
  // }
}

@media only screen and (max-width: 480px) {
  .card-feacher-left {
    margin-left: 30px;
    margin-right: 30px;
  }
}
