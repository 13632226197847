@font-face {
  font-family: "Effra";
  src: url("/assets/fonts/new/Effra.eot");
  src: url("/assets/fonts/new/Effra.eot") format("embedded-opentype"),
    url("/assets/fonts/new/Effra.woff") format("woff"), url("/assets/fonts/new/Effra.woff2") format("woff2"),
    url("/assets/fonts/new/Effra.ttf") format("truetype"), url("/assets/fonts/new/Effra.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Effra Bold";
  src: url("/assets/fonts/new/EffraBold.eot");
  src: url("/assets/fonts/new/EffraBold.eot") format("embedded-opentype"),
    url("/assets/fonts/new/EffraBold.woff") format("woff"), url("/assets/fonts/new/EffraBold.woff2") format("woff2"),
    url("/assets/fonts/new/EffraBold.ttf") format("truetype"), url("/assets/fonts/new/EffraBold.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Effra Italic";
  src: url("/assets/fonts/new/EffraItalic.eot");
  src: url("/assets/fonts/new/EffraItalic.eot") format("embedded-opentype"),
    url("/assets/fonts/new/EffraItalic.woff") format("woff"),
    url("/assets/fonts/new/EffraItalic.woff2") format("woff2"),
    url("/assets/fonts/new/EffraItalic.ttf") format("truetype"),
    url("/assets/fonts/new/EffraItalic.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Effra Medium";
  src: url("/assets/fonts/new/EffraMedium.eot");
  src: url("/assets/fonts/new/EffraMedium.eot") format("embedded-opentype"),
    url("/assets/fonts/new/EffraMedium.woff") format("woff"),
    url("/assets/fonts/new/EffraMedium.woff2") format("woff2"),
    url("/assets/fonts/new/EffraMedium.ttf") format("truetype"),
    url("/assets/fonts/new/EffraMedium.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "futura";
  src: url("/assets/fonts/new/futur.eot");
  src: url("/assets/fonts/new/futur.eot") format("embedded-opentype"),
    url("/assets/fonts/new/futur.woff") format("woff"), url("/assets/fonts/new/futur.woff2") format("woff2"),
    url("/assets/fonts/new/futur.ttf") format("truetype"), url("/assets/fonts/new/futur.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Bold Italic";
  src: url("/assets/fonts/new/FuturaBoldItalicfont.eot");
  src: url("/assets/fonts/new/FuturaBoldItalicfont.eot") format("embedded-opentype"),
    url("/assets/fonts/new/FuturaBoldItalicfont.woff") format("woff"),
    url("/assets/fonts/new/FuturaBoldItalicfont.woff2") format("woff2"),
    url("/assets/fonts/new/FuturaBoldItalicfont.ttf") format("truetype"),
    url("/assets/fonts/new/FuturaBoldItalicfont.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Bold";
  src: url("/assets/fonts/new/FuturaBold.eot");
  src: url("/assets/fonts/new/FuturaBold.eot") format("embedded-opentype"),
    url("/assets/fonts/new/FuturaBold.woff") format("woff"),
    url("/assets/fonts/new/FuturaBold.woff2") format("woff2"),
    url("/assets/fonts/new/FuturaBold.ttf") format("truetype"),
    url("/assets/fonts/new/FuturaBold.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Book";
  src: url("/assets/fonts/new/FuturaBookfont.eot");
  src: url("/assets/fonts/new/FuturaBookfont.eot") format("embedded-opentype"),
    url("/assets/fonts/new/FuturaBookfont.woff") format("woff"),
    url("/assets/fonts/new/FuturaBookfont.woff2") format("woff2"),
    url("/assets/fonts/new/FuturaBookfont.ttf") format("truetype"),
    url("/assets/fonts/new/FuturaBookfont.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Book Italic";
  src: url("/assets/fonts/new/FuturaBookItalicfont.eot");
  src: url("/assets/fonts/new/FuturaBookItalicfont.eot") format("embedded-opentype"),
    url("/assets/fonts/new/FuturaBookItalicfont.woff") format("woff"),
    url("/assets/fonts/new/FuturaBookItalicfont.woff2") format("woff2"),
    url("/assets/fonts/new/FuturaBookItalicfont.ttf") format("truetype"),
    url("/assets/fonts/new/FuturaBookItalicfont.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Medium Bt";
  src: url("/assets/fonts/new/futuraMediumBt.eot");
  src: url("/assets/fonts/new/futuraMediumBt.eot") format("embedded-opentype"),
    url("/assets/fonts/new/futuraMediumBt.woff") format("woff"),
    url("/assets/fonts/new/futuraMediumBt.woff2") format("woff2"),
    url("/assets/fonts/new/futuraMediumBt.ttf") format("truetype"),
    url("/assets/fonts/new/futuraMediumBt.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Futura Medium Italic";
  src: url("/assets/fonts/new/FuturaMediumItalicfont.eot");
  src: url("/assets/fonts/new/FuturaMediumItalicfont.eot") format("embedded-opentype"),
    url("/assets/fonts/new/FuturaMediumItalicfont.woff") format("woff"),
    url("/assets/fonts/new/FuturaMediumItalicfont.woff2") format("woff2"),
    url("/assets/fonts/new/FuturaMediumItalicfont.ttf") format("truetype"),
    url("/assets/fonts/new/FuturaMediumItalicfont.svg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: 'Unicode Futurab';
  src: url("/assets/fonts/new/unicode.futurab.eot");
  src: url("/assets/fonts/new/unicode.futurab.eot") format('embedded-opentype'),
       url("/assets/fonts/new/unicode.futurab.woff") format('woff'), url("/assets/fonts/new/unicode.futurab.woff2") format('woff2'),
       url("/assets/fonts/new/unicode.futurab.ttf") format('truetype'), url("/assets/fonts/new/unicode.futurab.svg") format('svg');
  font-display: swap;
}
