@import "../scss/variable.scss";

.survey_steps {
  width: 100%;
  // min-height: 100%;
  position: relative;
  height: 100%;
  @media (max-width: 1600px) {
    padding-bottom: 7px;
  }
  @media (max-width: 576px) {
    padding-bottom: 20px;
  }
  .cover_image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba($color: #2d3e43, $alpha: 0.46);
      animation: fade 1.5s ease-in 0s;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .container {
    max-width: 800px;
    &.custom_container {
      max-width: 1000px;
      @media (min-width: 1600px) {
        max-width: 1140px;
      }
      @media (max-width: 769px) {
        max-width: 800px;
      }
    }
  }
  .survery_step_inner {
    position: relative;
    .back_header_wrap {
      padding: 35px 0;
      @media (min-width: 1024px) and (max-width: 1440px) {
        padding: 30px 0;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 20px 0;
      }
      a {
        display: block;
        img {
          height: 40px;
          width: auto;
          @media (max-width: 768px) {
            height: 44px;
          }
          @media (max-width: 576px) {
            height: 34px;
          }
        }
        img.back_arrow {
          cursor: pointer;
          margin-right: 14px;
        }
        img.logo_steps {
          cursor: pointer;
        }
      }
    }
    .back_header_wrap {
      &.thankyou-modal {
        padding: 75px 0;
      }
    }
    .survey_box_wrap {
      background: #fff;
      box-shadow: 0px 14px 26px #00000033;
      .survery_progress_bar {
        .progress {
          background-color: rgba($color: #8ea986, $alpha: 0.34);
          border-radius: 0;
          height: 8px;
          .progress-bar {
            background-color: #8ea986;
          }
        }
      }
      .index-bar {
        margin: 30px 20px;
        .p-progressbar {
          height: 10px;
          border: none;
          border-radius: 0;
          margin: 0 20px;
          .p-progressbar-value {
            background: #8ea986;
          }
          .p-progressbar-label {
            display: none !important;
          }
        }
      }
      .survey_content_wrap {
        padding: 70px 40px;
        @media (max-width: 1600px) {
          padding: 50px 40px;
        }
        &.padding_xl {
          padding: 120px 40px;
          @media (max-width: 1600px) {
            padding: 80px 40px;
          }
          @media (max-width: 576px) {
            padding: 40px 15px !important;
          }
        }
        &.padding_md {
          padding: 60px 40px;
          @media (max-width: 1600px) {
            padding: 30px 40px;
          }
          @media (max-width: 576px) {
            padding: 30px 15px !important;
          }
        }
        &.padding_sm {
          padding: 30px 40px;
          @media (max-width: 1600px) {
            padding: 20px 40px !important;
          }
          @media (max-width: 576px) {
            padding: 20px 15px !important;
          }
        }
        @media (max-width: 576px) {
          padding: 20px 16px !important;
        }
        .survey_info_wrap {
          line-height: 24px;
          position: relative;
          p {
            font-size: 18px;
            color: #241f1f;
            line-height: 28px;
            margin-bottom: 20px;
            font-family: "EffraRegular", sans-serif;
            p {
              &:not(:last-child) {
                display: block !important;
              }
            }
            strong {
            font-family: "EffraRegular", sans-serif;
            }
            @media (max-width: 1600px) {
              font-size: 16px;
              line-height: 23px;
              margin-bottom: 8px;
            }
            @media (min-width: 1024px) and (max-width: 1440px) {
              font-size: 16px;
              line-height: 23px;
              margin-bottom: 15px;
            }
            @media (max-width: 576px) {
              font-size: 16px;
              line-height: normal;
              margin-bottom: 10px;
            }
          }

          img.tooltip-icon {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            margin-bottom: 11px;
            vertical-align: top;
            @media screen and (max-width: 575px) {
              vertical-align: middle;
            }
          }
        }
        .survery_options_wrap {
          &.first_form_step ul {
            max-width: 100% !important;
            display: flex;
            flex-wrap: wrap;
            li {
              max-width: 48%;
              margin-right: 2%;
              width: 100%;
              display: flex;
              align-items: center;
              @media (max-width: 576px) {
                max-width: 100%;
              }
              &.answer_wrap::after {
                top: auto;
              }
              &.answer_wrap::before {
                top: auto;
              }
            }
          }
          ul {
            max-width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            li.answer_wrap {
              position: relative;
              counter-increment: alphabeticList;
              padding: 8px 65px 8px 8px;
              background-color: #536c730d;
              cursor: pointer;
              border: 1px solid transparent;
              margin-bottom: 12px;
              transition: all 0.4s ease-in-out;
              display: flex;
              align-items: center;
              // @media (max-width: 1440px) {
              //     padding: 5px 8px;
              //     margin-bottom: 8px;
              // }
              &:hover {
                background-color: rgba($color: #536c73, $alpha: 0.12);
              }
              &.selected {
                border: 1px solid #536c73;
                background-color: #536c7329;
                &::before {
                  background-color: #536c73;
                  color: #fff;
                }
                &::after {
                  opacity: 1;
                }
                & .other-option {
                  background-color: #536c73;
                  color: #fff;
                }
              }
              &:before {
                content: counter(alphabeticList, upper-alpha);
                speak: counter(alphabeticList, upper-alpha);
                position: absolute;
                width: 28px;
                height: 28px;
                background-color: #536c7329;
                font-size: 20px;
                text-align: center;
                color: #536c73;
                top: 8px;
                line-height: 27px;
                @media (max-width: 1600px) {
                  font-size: 16px;
                  width: 22px;
                  height: 22px;
                  line-height: 24px;
                }
                @media (max-width: 576px) {
                  font-family: "PT Mono", monospace;
                  font-size: 14px;
                }
              }
              &::after {
                position: absolute;
                content: "";
                background: url(../../assets/img/check.svg) no-repeat center;
                background-size: contain;
                width: 23px;
                height: 23px;
                top: 11px;
                right: 10px;
                opacity: 0;
              }
              p {
                padding: 0 30px 0 50px;
                margin-bottom: 0;
                font-size: 18px;
                color: #241f1f;
                font-weight: 400;
                line-height: revert;
                font-family: "EffraRegular", sans-serif;
                *{
                  font-family: "EffraRegular", sans-serif;
                }
                @media (max-width: 1600px) {
                  font-size: 16px;
                }
                @media (max-width: 1199px) {
                  font-size: 14px;
                  // padding-left: 35px;
                  margin-top: 3px;
                }
              }

              .custam_answer_input {
                background: transparent;
                border: 0;
                padding: 0 30px 0 50px;
                font-size: 18px;
                color: #241f1f;
                font-family: "EffraRegular", sans-serif;
                @media (max-width: 1600px) {
                  font-size: 16px;
                }
                @media (max-width: 1199px) {
                  font-size: 14px;
                  padding-left: 35px;
                }
                &::-webkit-input-placeholder {
                  color: #241f1f;
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
          & .singup_form {
            .custam_textarea {
              @media (min-width: 1024px) and (max-width: 1440px) {
                height: 75px;
                margin-bottom: 0;
              }
              @media (min-width: 768px) and (max-width: 1024px) {
                height: 62px;
              }
              @media (max-width: 576px) {
                margin-bottom: 0;
              }
            }
          }
        }
        .feedback {
          background-color: #8ea98626;
          position: relative;
          padding: 20px 18px 14px 18px;
          margin-top: 25px;
          font-size: 18px;
          img.feedback_icon {
            position: absolute;
            top: -7px;
            right: 15px;
            width: 34px;
            height: 29px;
          }
          p {
            margin-bottom: 0;
            font-size: 18px;
            color: #000000;
            line-height: revert;
            font-family: "Effra",sans-serif;
            *{
            font-family: "Effra",sans-serif;
            }
            &.info_text {
              margin-top: -19px;
            }
            @media (max-width:1600px) {
              font-size: 16px;
            }
            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
          a {
            color: #536c73 !important;
            text-decoration: underline !important;
            cursor: pointer;
            font-size: 18px;
            font-weight: bolder;
            .info {
              font-size: 33px;
              margin-left: 4px;
              &::before {
                transform: rotate(220deg);
                padding-bottom: 7px;
              }
            }
          }
        }
        .vendors-questions-tooltip {
          position: absolute;
          right: 40px;
          top: 2px;
          width: 20px;
          display: inline-block;
          .tooltip-icon {
            margin-left: 0 !important;
            width: 20px;
            height: 20px;
          }
          @media screen and (max-width: 575px) {
            top: 0;
          }
          label {
            z-index: inherit;
            .p-tooltip-arrow {
              z-index: 9999;
              transform: translateX(-50%);
              left: 50%;
            }
          }
          .p-tooltip {
            min-width: 335px !important;
            margin-top: 0;
            .tooltip_content {
              .info_icon {
                padding: 0;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
          .p-tooltip-min-2 {
            min-width: 300px !important;
            margin-top: 0;
            @media (max-width: 336px) {
              min-width: 282px !important;
            }
          }
          &.dropdown {
            .p-tooltip-arrow {
              top: 9px !important;
              @media screen and (max-width: 991px) {
                display: none;
              }
              @media screen and (max-width: 575px) {
                top: 13px !important;
              }
            }
            .dropdown-menu {
              min-width: max-content !important;
              @media screen and (min-width: 992px) {
                display: block;
                opacity: 0;
                visibility: hidden;
              }
              .p-tooltip-text {
                max-width: 480px;
                @media screen and (max-width: 575px) {
                  max-width: 400px;
                }
                @media screen and (max-width: 480px) {
                  max-width: 350px;
                }
                @media screen and (max-width: 380px) {
                  max-width: 280px;
                }
              }
              &.dropdown-menu-end {
                @media screen and (min-width: 992px) {
                  top: 100% !important;
                  transform: none !important;
                  right: 0 !important;
                  left: auto !important;
                }
              }
              &.dropdown-menu-start {
                @media screen and (min-width: 992px) {
                  top: 100% !important;
                  transform: none !important;
                  left: 0 !important;
                  right: auto !important;
                }
              }
              &[data-popper-placement="top-end"] {
                margin-bottom: 15px !important;
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
              }
            }
            .show {
              .p-tooltip-arrow {
                @media screen and (max-width: 991px) {
                  display: block;
                }
              }
            }
          }
          &.bottom-arrow {
            .p-tooltip-arrow {
              top: auto !important;
              bottom: 14px;
              transform: rotate(180deg);
              transform-origin: 10px center;
            }
          }
        }
        .question {
          + .vendors-questions-tooltip {
            position: relative;
            right: 0;
            // z-index: 99999;
            margin-left: 8px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.form-group {
  position: relative;
  padding-bottom: 16px;
  // @media (max-width: 1440px) {
  //   padding-bottom: 13px;
  // }
  @media (max-width: 576px) {
    padding-bottom: 15px;
  }
  &.invalid-error {
    padding-bottom: 34px;
  }
  label {
    color: #241f1f;
    display: block;
    font-size: 16px;
    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }
  input {
    font-size: 16px;
    height: 56px;
    padding: 18px 14px;
    border: 0;
    border-bottom: 2px solid $gray2;
    background-color: $light;
    border-radius: 0;
    line-height: 1.5;
    border-radius: 4px 4px 0 0;
    @media screen and (max-width: 600px) {
      font-family: "EffraRegular";
    }
    &::-webkit-input-placeholder {
      color: #808080;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: $light;
      border-bottom: 2px solid #373737;
    }
    @media (max-width: 575px) {
      height: 48px;
      font-size: 14px;
    }
    @media (max-width: 600px) {
    }
  }
  .optional_text {
    position: absolute;
    font-size: 16px;
    line-height: revert;
    margin-bottom: 0;
    right: 0;
    color: #bdbcbc;
    top: 5px;
    font-style: italic;
    @media (max-width: 1600px) {
      font-size: 14px;
      top: 3px;
    }
  }
}
.custam_textarea {
  font-family: "EffraRegular", sans-serif;
  padding: 8px 14px;
  resize: none;
  background-color: #f9f9f9;
  border: 1px solid transparent;
  color: #241f1f;
  font-size: 16px;
  margin-bottom: 12px;
  &::-webkit-input-placeholder {
    color: #bdbcbc;
    font-family: "EffraRegular", sans-serif;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #a7a7a7;
    background-color: #f9f9f9;
  }
}
.comman_anchor {
  border: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 8px 46px;
  color: #fff;
  font-size: 24px;
  display: inline-flex;
  background-color: #536c73;
  margin-top: 5px;
  transition: all 0.4s ease-in-out;
  font-family: "EffraRegular", sans-serif;
  &:hover {
    background-color: #42555b;
    color: #fff !important;
  }
  @media (max-width: 1600px) {
    padding: 7px 36px;
    font-size: 16px;
  }
}
.country_dropdown {
  .country_selecter {
    padding: 0 14px;
    background-color: #f9f9f9;
    border: 0;
    width: 100%;
    text-align: left;
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    @media (max-width: 1600px) {
      height: 44px;
      padding: 12px 14px;
    }
    &::after {
      position: absolute;
      content: "";
      background-image: url(../../assets/img/country_drop_arrow.svg);
      width: 25px;
      height: 25px;
      right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    img {
      width: 30px;
      height: 20px;
      margin-right: 12px;
      object-fit: cover;
      @media (max-width: 1600px) {
        width: 24px;
      }
    }
    span {
      font-size: 16px;
      color: #241f1f;
      margin-bottom: 0;
      @media (max-width: 1600px) {
        font-size: 16px;
      }
    }
  }
  .dropdown-menu {
    width: 100%;
    padding: 8px;
    height: 200px;
    overflow-y: scroll;
    li {
      padding: 8px;
      cursor: pointer;
      display: block;
      &:hover {
        background-color: #f9f9f9;
      }
      img {
        width: 30px;
        height: 20px;
        margin-right: 12px;
        object-fit: cover;
        @media (max-width: 1600px) {
          width: 24px;
        }
      }
      span {
        font-size: 16px;
        color: #241f1f;
        margin-bottom: 0;
        @media (max-width: 1600px) {
          font-size: 16px;
        }
      }
    }
  }
  .invalid-controls {
    bottom: 0px;
    font-family: "Effra";
  }
}
.confirmation_box_wrap {
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    min-height: 300px;
  }
  .confirmation_text {
    text-align: center;
    max-width: 600px;
    a {
      color: #536c73 !important;
      cursor: pointer;
    }
    img {
      margin-bottom: 26px;
      @media (max-width: 1600px) {
        margin-bottom: 16px;
      }
    }
    h3 {
      font-family: "EffraRegular", sans-serif;
      color: #241f1f;
      font-size: 30px;
      max-width: 521px;
      margin: 0 auto 36px;
      line-height: 45px;
      @media (max-width: 1600px) {
        margin: 0 auto 26px;
        font-size: 22px;
        line-height: 42px;
      }
      @media screen and (max-width: 380px) {
        line-height: 32px;
      }
    }
    .comman_anchor {
      padding: 8px 22px;
      color: #fff !important;
      cursor: pointer;
    }
    p {
      @media screen and (max-width: 460px) {
        br {
          display: none;
        }
      }
    }
  }
}
.phone_number_drop {
  select {
    background-color: rgba($color: #536c73, $alpha: 0.05);
    width: 96px;
    font-size: 20px;
    padding: 16px 14px;
    border: 0;
    height: 56px;
    color: #241f1f;
    background-image: url(../../assets/img/dropdown_arrow.svg);
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: center right;
    line-height: 16px;
    &:focus {
      outline: none;
    }
    @media (max-width: 1600px) {
      height: 44px;
      font-size: 16px;
      padding: 6px 10px;
    }
  }
  input {
    border-bottom: 0;
    &:focus {
      border: 0;
    }
  }
}
.error_text {
  color: red;
  font-size: 16px;
  line-height: 20px;
  font-family: "EffraRegular", sans-serif;
}
.invalid-controls {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #dc3545;
  span {
    font-family: "EffraRegular";
    &.drop-err-msg{
      font-size: 12px;
    }
  }
}
.why-single-point {
  position: relative;
  p {
    a {
      color: #536c73 !important;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  > .custom_tooltip {
    background-color: #fff;
    box-shadow: 0 0 10px 2px #00000033;
    min-width: 600px;
    padding: 25px 15px;
    position: absolute;
    top: 80%;
    left: 24%;
    z-index: 1;
    // opacity: 0;
    // visibility: hidden;
    @media (max-width: 1600px) {
      max-width: 400px;
      top: 80%;
    }
    &::after {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      top: -20px;
      z-index: -1;
      background-image: url(../../assets/img/drop_arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .tooltip_content {
      p {
        font-size: 20px;
        position: relative;
        padding-left: 35px;
        @media (max-width: 1600px) {
          font-size: 16px;
        }
        &::after {
          position: absolute;
          top: 0;
          left: 0px;
          height: 24px;
          width: 24px;
          content: "\f430";
          font-family: "bootstrap-icons" !important;
          font-size: 20px;
          color: #536c73;
          transition: 0.3s;
          background: #ffffff;
        }
      }
      a.tooltip_link {
        font-size: 20px;
        text-decoration: underline;
        padding-left: 35px;
        position: relative;
        color: #241f1f;
        @media (max-width: 1600px) {
          font-size: 16px;
        }
        &::after {
          position: absolute;
          top: -3px;
          left: 0px;
          height: 24px;
          width: 16px;
          content: "\f140";
          font-family: "bootstrap-icons" !important;
          font-size: 20px;
          color: #8ea986;
          transition: 0.3s;
        }
      }
    }
    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}
.lang_dropdown {
  width: 100%;
  .lang_selecter {
    position: relative;
    background-color: transparent;
    padding: 7px 20px 7px 8px;
    font-family: "EffraRegular", sans-serif;
    height: 46px;
    width: 105px;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    margin-left: 20px;
    margin-top: 0px;
    @media (min-width: 768px) and (max-width: 1440px) {
      height: 40px;
      margin-top: 2px;
    }
    @media (max-width: 991px) {
      margin-left: 0;
      margin-top: 0px;
      width: 100px;
      order: 1;
      border: 0;
    }
    @media (max-width: 575px) {
      width: 90px;
    }
    @media (max-width: 1600px) {
      height: 40px;
    }
    &.show::after {
      transform: translateY(-52%) rotate(180deg);
    }
    &::after {
      position: absolute;
      content: "";
      background-image: url(../img/lang_drop_arrow.svg);
      width: 25px;
      height: 25px;
      right: 5px;
      top: 52%;
      transform: translateY(-52%);
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;
      @media screen and (max-width: 575px) {
        width: 15px;
        height: 15px;
        right: 10px;
      }
    }
    img {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      object-fit: cover;
      border: 2px solid #fff;
      @media (min-width: 768px) and (max-width: 1440px) {
        width: 22px;
        height: 22px;
      }
      @media (max-width: 575px) {
        width: 20px;
        height: 20px;
      }
    }
    span {
      color: #fff;
      font-size: 20px;
      margin-left: 10px;
      @media (min-width: 991px) and (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
  }
  .lang_menu {
    min-width: 155px;
    right: 0;
    left: auto;
    padding: 10px 10px 5px;
    border-radius: 0;
    margin-top: 7px;
    @media (max-width: 992px) {
      position: absolute;
      margin-top: -8px;
      left: -10px;
    }
    li {
      padding-bottom: 9px;
      margin-bottom: 9px;
      border-bottom: 1px solid #d3d2d2;
      line-height: initial;
      font-size: 20px;
      @media (min-width: 768px) and (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
      }
      label {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        margin-bottom: 0;
        .flag_image {
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            margin-left: 10px;
          }
          img {
            width: 25px;
            height: 25px;
            border-radius: 50px;
            object-fit: cover;
            @media (min-width: 768px) and (max-width: 1440px) {
              width: 22px;
              height: 22px;
            }
            @media (max-width: 575px) {
              width: 20px;
              height: 20px;
            }
          }
        }
        .custom-radios {
          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 17px;
            height: 17px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #bdbcbc;
            @media (max-width: 575px) {
              width: 14px;
              height: 14px;
            }
            img {
              opacity: 0;
              width: 13px;
              transition: all 0.3s ease;
            }
          }
          span.checked {
            border: 1px solid transparent;
            background-color: #8ea986;
          }
          span.checked img {
            opacity: 1;
          }
        }
      }
      &:last-child {
        border: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
.custom_phone_drop {
  .num_selecter {
    height: 56px;
    padding: 15px 25px 15px 14px;
    background-color: #f9f9f9;
    border-radius: 0;
    position: relative;
    color: #242424;
    @media (max-width: 1600px) {
      height: 44px;
      padding: 6px 14px;
      width: 70px;
      text-align: left;
    }
    &::after {
      position: absolute;
      content: "";
      background: url(../img/dropdown_arrow.svg) no-repeat right;
      width: 20px;
      height: 20px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 1600px) {
        width: 15px;
        height: 15px;
        object-fit: contain;
        top: 52%;
        transform: translateY(-53%);
      }
    }
    span {
      font-size: 18px;
      color: #242424;
      margin-bottom: 0;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
    }
  }
  .call_num_menu {
    padding: 0 0 !important;
    height: 200px;
    overflow-y: scroll;
    min-width: 210px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      cursor: pointer;
      &.code-selected {
        background-color: #ebf5ff;
        .country_number {
          span {
            color: #242424;
          }
        }
      }
      &:last-child {
        margin: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
      .country_number {
        span {
          color: #bdbcbc;
        }
      }
    }
  }
}

.other-option {
  position: absolute;
  z-index: 9;
  min-width: 28px;
  width: fit-content;
  background-color: #dbe1e2;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #536c73;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
}

@keyframes fade {
  0% {
    filter: blur(5px);
  }
  50% {
    filter: blur(3px);
  }
  100% {
    filter: blur(0px);
  }
}
@-moz-keyframes fade {
  0% {
    -moz-filter: blur(5px);
  }
  50% {
    -moz-filter: blur(3px);
  }
  100% {
    -moz-filter: blur(0px);
  }
}
@-o-keyframes fade {
  0% {
    -o-filter: blur(5px);
  }
  50% {
    -o-filter: blur(3px);
  }
  100% {
    -o-filter: blur(0px);
  }
}
@-webkit-keyframes fade {
  0% {
    -webkit-filter: blur(5px);
  }
  50% {
    -webkit-filter: blur(3px);
  }
  100% {
    -webkit-filter: blur(0px);
  }
}

.list-resources {
  @media (max-width: 576px) {
    margin-top: 22px;
  }
  ul {
    li {
      padding: 10px 35px 10px 10px;
      background-color: #edf0f1;
      list-style: none;
      font-size: 16px;
      margin-bottom: 10px;
      color: #42555b;
      position: relative;
      .option_name {
        ::ng-deep {
          p {
            font-size: 20px !important;
            line-height: 27px;
          }
        }
      }
      .custam_answer_input {
        background: transparent;
        border: 0;
        padding: 0 30px 0 35px;
        font-size: 19px;
        color: #241f1f;
        font-family: "EffraRegular", sans-serif;
        line-height: normal;
        // @media (max-width: 1600px) {
        //   font-size: 16px;
        // }
        @media (max-width: 576px) {
          font-size: 16px;
          padding-left: 35px;
        }
        &::-webkit-input-placeholder {
          color: #241f1f;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .selected {
      border: 1px solid #000;
    }
  }
}

.hotel-details {
  margin: 40px 0;
  p,
  span {
    font-size: 22px;
    line-height: 28px;
    &:not(:last-child) {
      margin-bottom: 20px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 1600px) {
      line-height: 25px;
      font-size: 20px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  li {
    font-size: 22px;
    line-height: 28px;
    &:not(:last-child) {
      margin-bottom: 20px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 1600px) {
      font-size: 20px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.new_question_wrap {
  p {
    font-size: 28px;
    @media screen and (max-width: 1440px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    }
    .vendors-questions-tooltip {
      position: static !important;
    }
  }
}
