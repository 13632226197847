@import "./assets/scss/variable.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

$utilities: (
  "order": (
    property: order,
    class: order,
    responsive: true,
    values: (
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10
    )
  )
);

@import "./assets/fonts/fonts.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "FuturaPT";
  src: url("./assets/fonts/futuraPTbold.eot");
  src:
    url("./assets/fonts/futuraPTbold.eot") format("embedded-opentype"),
    url("./assets/fonts/futuraPTbold.woff2") format("woff2"),
    url("./assets/fonts/futuraPTbold.woff") format("woff"),
    url("./assets/fonts/futuraPTbold.ttf") format("truetype"),
    url("./assets/fonts/futuraPTbold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "EffraRegular";
  src: url("./assets/fonts/effra-regular.eot");
  src:
    url("./assets/fonts/effra-regular.eot") format("embedded-opentype"),
    url("./assets/fonts/effra-regular.woff") format("woff2"),
    url("./assets/fonts/effra-regular.woff") format("woff"),
    url("./assets/fonts/effra-regular.ttf") format("truetype"),
    url("./assets/fonts/effra-regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EffraLight";
  src: url("./assets/fonts/effra-light.eot");
  src:
    url("./assets/fonts/effra-light.eot") format("embedded-opentype"),
    url("./assets/fonts/effra-light.woff2") format("woff2"),
    url("./assets/fonts/effra-light.woff") format("woff"),
    url("./assets/fonts/effra-light.ttf") format("truetype"),
    url("./assets/fonts/effra-light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EffraMedium";
  src: url("./assets/fonts/effra-medium.eot");
  src:
    url("./assets/fonts/effra-medium.eot") format("embedded-opentype"),
    url("./assets/fonts/effra-medium.woff2") format("woff2"),
    url("./assets/fonts/effra-medium.woff") format("woff"),
    url("./assets/fonts/effra-medium.ttf") format("truetype"),
    url("./assets/fonts/effra-medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura";
  src: url("./assets/fonts/futura.eot");
  src:
    url("./assets/fonts/futura.eot") format("embedded-opentype"),
    url("./assets/fonts/futura.woff2") format("woff2"),
    url("./assets/fonts/futura.woff") format("woff"),
    url("./assets/fonts/futura.ttf") format("truetype"),
    url("./assets/fonts/futura.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* ==========================================================================
   Basic CSS
   ========================================================================== */
* {
  font-family: "Futura Book";
}

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // overflow-y: auto;
  scroll-behavior: smooth;
  font-family: "Futura Book";
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

body {
  background-color: #ffffff;
  font-family: "Futura Book";
  font-size: 24px;
  color: #1b1919;
  min-height: 100%;
}

.mat-body,
.mat-body-1 {
  font-size: 24px;
  color: #1b1919;
  font-family: "Futura Book";
}

i {
  display: inline-block;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  &.vendor-anchor {
    font-size: 18px;
  }
  &:hover {
    // color: #536c73;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

h2,
.mat-h2,
.mat-title,
.mat-typography h2,
.mat-typography .h2 {
  font-family: "Futura Medium Bt";
  font-size: 64px;
  line-height: 72px;

  @media screen and (max-width: 1199px) {
    font-size: 54px;
    line-height: 62px;
  }

  @media screen and (max-width: 991px) {
    font-size: 46px;
    line-height: 54px;
  }

  @media screen and (max-width: 767px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media screen and (max-width: 575px) {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-family: "Futura Book";
  font-size: 22px;
  line-height: 36px;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.underline-none {
  text-decoration: none !important;
}

.underline {
  text-decoration: underline !important;
}

.home-container {
  max-width: calc(1360px + 32px);
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (max-width: 1440px) {
    max-width: calc(1220px + 32px);
  }
}

.container {
  max-width: calc(1360px + 32px);

  @media screen and (max-width: 1440px) {
    // max-width: 1252px;
  }

  @media screen and (max-width: 575px) {
    // max-width: 98%;
  }

  // @media screen and (min-width: 575px) {
  //   max-width: 85%;
  // }
}

.small-container {
  max-width: 1152px;
  margin: 0 auto;
  padding: 0 16px;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.btn {
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 26px;
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 12px;
  }

  & i {
    margin-left: 8px;
    line-height: normal;

    svg {
      @media screen and (max-width: 575px) {
        width: 16px;
        height: 16px;
        vertical-align: -4px;
      }
    }
  }

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  &.btn-white {
    background: #ffffff;
    color: #1b1919;
  }

  &.btn-outline-light {
    border: 1px solid #ededed;
    color: $primary;
  }

  &.btn-sm {
    i {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.btn-light {
    color: $primary;

    i {
      margin-left: 8px;
    }
  }

  &.btn-gray {
    background-color: $light;
    color: $primary;
  }
}

.btn-check {
  &:focus + .btn {
    outline: 0;
    box-shadow: none;
  }

  &:focus + .btn-one {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }

  &:focus + .btn-two {
    color: #241f1f;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 50%);
  }

  &:focus + .btn-signup {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }

  &:focus + .btn-why {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }
}

.btn-one {
  color: #ffffff;
  background-color: #536c73;
  border-color: #536c73;
  transition: 0.3s;

  &:hover {
    color: #ffffff;
    background-color: #42555b;
    border-color: #42555b;
  }

  &:focus {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }
}

.btn-two {
  color: #241f1f;
  background-color: #ffffff;
  border-color: #ffffff;
  transition: 0.3s;

  &:hover {
    color: #241f1f;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
  }

  &:focus {
    color: #241f1f;
    background-color: #ffffff;
    border-color: #ffffff;
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 50%);
  }
}

.btn-signup {
  color: #536c73;
  background-color: #ffffff;
  border-color: #536c73;
  transition: 0.3s;

  &:hover {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
  }

  &:focus {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }
}

.btn-why {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  border-color: #ffffff;
  transition: 0.3s;

  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #ffffff;
  }

  &:focus {
    color: #ffffff;
    background-color: #536c73;
    border-color: #536c73;
    box-shadow: 0 0 0 0.25rem rgb(83 108 115 / 50%);
  }
}

.btn-outline-gray {
  background-color: transparent;
  border-color: #dbdbdb;
  color: $primary;
}

/* ==========================================================================
   HEADER SECTION
   ========================================================================== */

.header {
  position: relative;

  &.header-border {
    @media screen and (min-width: 768px) {
      border-bottom: 1px solid $gray2;
    }
  }
}

.lang_dropdown {
  .lang_selecter {
    border: 1px solid #d3d2d2;

    @media screen and (max-width: 575px) {
      border: 0;
    }

    &::after {
      background-image: url(/assets/img/country_drop_arrow.svg);
    }

    span {
      color: #42555b;
    }
  }
}

.navbar {
  padding: 24px 0;

  @media screen and (max-width: 991px) {
    padding: 18px 0;
  }

  .navbar-brand {
    font-size: 1rem;

    img {
      height: 44px;
      width: auto;

      @media screen and (max-width: 991px) {
        height: 32px;
      }
    }
  }

  .nav-item {
    display: inline-block;
    padding: 7px 19px;

    @media screen and (max-width: 1199px) {
      padding: 7px 15px;
    }

    @media screen and (max-width: 991px) {
      padding: 7px 0;
    }

    .nav-link {
      font-size: 20px;
      color: #1b1919;
      font-family: "Futura Book";
      padding: 0;

      @media screen and (max-width: 1199px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 32px;
        padding: 24px 0;
        color: #ffffff;
        border-bottom: 1px solid #4c7752;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          background-image: url("./assets/img/right_arrow.svg");
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .navbar-toggler {
    padding: 0;
    line-height: 18px;
    border: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .menu_bar {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .menu_nav {
        order: 2;
      }
    }
  }

  .signup_nav {
    padding: 0 0 0 24px;
    margin: 0;
    position: relative;

    @media screen and (max-width: 1199px) {
      padding-left: 16px;
    }

    @media screen and (max-width: 991px) {
      order: 1;
      padding: 0 0 8px;
    }

    .nav-item {
      padding: 0;

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }

    &.menu-open {
      .arrow {
        transform: rotate(180deg);
      }

      .user-menu {
        display: block;
      }
    }

    .signup-link {
      padding: 12px 15px;
      border: 2px solid #ededed;
      border-radius: 60px;
      display: inline-flex;
      line-height: normal;

      @media screen and (max-width: 1199px) {
        padding: 10px 15px;
      }

      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .user-menu {
      padding: 16px;

      @media screen and (min-width: 992px) {
        position: absolute;
        display: none;
        right: 0;
        min-width: 200px;
        margin-top: 8px;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        z-index: 1;
      }

      @media screen and (max-width: 991px) {
        padding: 4px 20px;
        border: 1px solid #4c7752;
      }
      .vender-dropdown {
        min-width: 248px;

        p {
          color: $primary;
          font-size: 18px;
          font-family: "Futura Medium Bt";
          margin-bottom: 10px;
          line-height: 24px;
          @media screen and (max-width: 991px) {
            color: $white;
          }

          i {
            line-height: 1;

            svg {
              vertical-align: top;
            }
          }
        }

        ul {
          padding: 0 12px;
          background-color: $light1;
          @media (max-width: 991px) {
            background-color: $primary;
          }

          li {
            &:last-child {
              .nav-link {
                border-bottom: 0;
              }
            }

            .nav-link {
              padding: 12px 0 12px 0;
              font-size: 14px;
              line-height: 16px;
              font-family: "Futura Medium Bt";
              background-color: $light1;
              color: $text1;
              margin-top: 0;
              border-bottom: 1px solid $gray2;
              @media (max-width: 991px) {
                background-color: $primary;
                color: $white;
                border-bottom-color: #4c7752;
              }
              &:after {
                content: normal;
              }
              img {
                height: 16px;
                width: 16px;
              }
            }
          }
        }
      }
      .nav-link {
        font-size: 18px;
        line-height: 24px;
        white-space: nowrap;
        padding: 12px;
        width: 100%;
        display: inline-flex;
        background-color: $light;
        justify-content: space-between;

        & + .nav-link {
          margin-top: 12px;

          @media screen and (max-width: 991px) {
            border-bottom: 0;
            margin-top: 0;
          }
        }

        @media screen and (max-width: 991px) {
          background-color: transparent;
          padding: 16px 0;
        }
      }
    }
  }
}

.navbar_menu {
  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 22px 24px 64px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #38573c;
    transition: all 0.5s ease-in-out;
    transform: translateX(100%);
    z-index: 110;

    &.show {
      transform: translateX(0);
    }
  }

  .mobile_header {
    display: none;

    @media screen and (max-width: 991px) {
      display: block;
      margin-bottom: 30px;

      .mobile_header_inner {
        text-align: right;
      }
    }
  }
}

mat-label {
  font-size: 16px;

  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
}

.mat-form-field {
  width: 100%;

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      padding: 3px 15px 6px;
      font-size: 16px;
      align-items: center;

      @media screen and (max-width: 575px) {
        padding: 3px 15px 5px;
        font-size: 14px;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: $dark;
        -webkit-box-shadow: 0 0 0px 40rem #f6f6f6 inset;
      }
    }

    .mat-select-arrow-wrapper {
      transform: none;
    }

    .mat-form-field-subscript-wrapper {
      padding: 0;
      margin-top: 3px;
    }
  }

  &.mat-form-field-invalid {
    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: $errorBg;

        .mat-select-placeholder {
          color: $dark;
        }

        input::-webkit-input-placeholder {
          color: $dark;
        }

        input::-moz-placeholder {
          color: $dark;
        }

        input:-ms-input-placeholder {
          color: $dark;
        }

        input:-moz-placeholder {
          color: $dark;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
          -webkit-text-fill-color: $dark;
          -webkit-box-shadow: 0 0 0px 40rem $errorBg inset;
        }
      }
    }

    .mat-select-arrow {
      border-bottom-color: $error;
      border-right-color: $error;
    }

    .mat-icon {
      color: $error;
    }
  }

  .mat-select-arrow {
    border-bottom: 2px solid $dark;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border-right: 2px solid $dark;
    border-left: 0;
    border-top: 0;
  }
}

.mat-select-panel {
  .mat-option {
    border-bottom: 1px solid $gray2;
    padding: 0;
    margin: 0 16px;

    &.mat-selected {
      color: $dark !important;

      &:not(.mat-option-multiple) {
        background-color: transparent;
      }

      &::before {
        content: "";
        position: absolute;
        right: 5px;
        top: 12px;
        width: 7px;
        height: 16px;
        border-top: 2px solid $primary;
        border-left: 2px solid $primary;
        transform: rotate(-128deg);
      }
    }

    &:hover,
    &.mat-active {
      background-color: transparent;
    }
  }
}

.hideScroll {
  overflow: hidden;
}

.hero-area {
  padding: 80px 0px;

  h2 {
    font-family: "FuturaPT", sans-serif;
    color: #ffffff;
    font-size: 66px;
    line-height: 100px;
    margin-bottom: 24px;

    > span {
      @media screen and (max-width: 700px) {
        display: block;
      }
    }
  }

  h5 {
    font-family: "FuturaPT", sans-serif;
    color: #ffffff;
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 45px;
  }

  ul {
    li.list-inline-item {
      &:not(:last-child) {
        margin-right: 1.3rem;
      }

      .btn {
        @media (max-width: 991px) {
          font-size: 20px;
        }

        @media screen and (max-width: 575px) {
          font-size: 18px;
          padding: 5px 20px;
        }

        @media screen and (max-width: 375px) {
          font-size: 17px;
          padding: 5px 16px;
        }
      }
    }
  }
}

/* ==========================================================================
   WHY SECTION
   ========================================================================== */

.why {
  background: #ffffff;
  padding: 230px 0px 0px;
  color: #241f1f;
}

.why-title {
  margin-bottom: 45px;
}

.why-single-point {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 24px;

  img {
    display: block;
    margin-bottom: 44px;
  }

  h4 {
    color: #241f1f;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 16px;
  }
}

.result-points {
  margin-bottom: 45px;
}

.single-result {
  position: relative;
  background-color: #38573c;

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(14, 14, 14, 0.56) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(14, 14, 14, 0.56) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(14, 14, 14, 0.56) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#0e0e0e", GradientType=1);
  }

  h4 {
    position: absolute;
    left: 24px;
    bottom: 36px;
    color: #ffffff;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 0px;
    padding-right: 10px;
    word-break: break-word;

    @media screen and (max-width: 576px) {
      padding-right: 0;
    }
  }
}

.result-points .owl-nav {
  display: flex;
  position: absolute;
  right: 10px;
  top: -110px;
}

.result-points .owl-nav .owl-next,
.result-points .owl-nav .owl-prev {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #8ea986;
  color: #fff;
  text-align: center;
  font-size: 26px;
  line-height: normal;
  transition: 0.3s;
  padding: 0;
  margin: 0 0 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consequences {
  font-family: "Futura", sans-serif;
  font-size: 26px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: #241f1f;

  a {
    position: relative;
    text-decoration: underline;
    color: #241f1f;
    transition: 0.2s;

    &:after {
      position: absolute;
      top: 2px;
      right: -40px;
      height: 26px;
      width: 26px;
      content: "\f141";
      font-family: "bootstrap-icons" !important;
      font-size: 26px;
      color: #241f1f;
      transition: 0.3s;
      background: #ffffff;
    }
  }
}

/* ==========================================================================
   BLOG SECTION
   ========================================================================== */

.blog-card-row {
  @media screen and (max-width: 575px) {
    margin: 0 -7px;
  }

  & .col-6 {
    @media screen and (max-width: 575px) {
      padding: 0 7px;
    }
  }

  .blog-card {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);

    @media screen and (max-width: 575px) {
      margin-bottom: 14px;
      height: calc(100% - 14px);
    }

    .card-image-wrap {
      img {
        height: 240px;
        width: 100%;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          height: 120px;
        }
      }
    }

    .card-desc-wrap {
      padding: 20px;
      background-color: #fff;

      @media screen and (max-width: 767px) {
        padding: 8px;
      }

      .blog-type {
        @media screen and (min-width: 768px) {
          font-family: "Futura Medium Bt";
          font-size: 16px;
          line-height: 20px;
          top: 16px;
          right: 16px;
          position: absolute;
          padding: 6px 12px;
          background-color: #fff;
          border-radius: 50px;
          color: #38573c;
        }

        @media screen and (max-width: 767px) {
          font-family: "Effra";
          font-size: 12px;
          line-height: 16px;
          color: #808080;
          text-transform: uppercase;
        }
      }

      h4 {
        font-family: "Futura Medium Bt";
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 12px;
        }
      }

      .btn-sm {
        span {
          font-family: "Futura Medium Bt";

          @media screen and (max-width: 767px) {
            text-decoration: underline;
          }
        }

        @media screen and (max-width: 767px) {
          padding: 4px 0;
          border: 0;
        }
      }
    }
  }
}

.view-more {
  position: relative;
  text-align: center;
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    background-color: #ededed;
    z-index: -1;
  }

  .btn-outline-light {
    background: #f8fafb;
    color: #1b1919;
    font-size: 16px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }

    i {
      transform: rotate(90deg);

      ::ng-deep svg path {
        fill: #1b1919;
      }
    }
  }
}

/* ==========================================================================
   HOW SECTION
   ========================================================================== */
.how {
  background: #f9f9f9;
  padding: 80px 0px;
}

.how-content {
  h2 {
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 32px;

    i.bi {
      font-size: 38px;
      color: #536c73;
    }

    &.how-line-text-2 {
      max-width: 991px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  a {
    color: #536c73 !important;
    // text-decoration: underline;
    cursor: pointer;
  }
}

.how-font-width {
  .how-text-line {
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1600px) {
      max-width: 700px;
    }
  }
}

.how-slider {
  padding: 0;

  .owl-carousel {
    padding: 0px;
  }

  .owl-nav {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -90px;

    @media screen and (max-width: 557px) {
      padding-right: 200px;
    }

    .owl-next {
      height: 54px;
      width: 54px;
      border-radius: 50%;
      background: $gray2;
      color: #fff;
      margin-left: 15px;
      text-align: center;
      font-size: 24px;
      line-height: normal;
      transition: 0.3s;
      padding: 0;
      margin: 0 0 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 556px) {
        height: 48px;
        width: 48px;
      }

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 46%;
        border: solid $gray;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: translate(-50%, -50%) rotate(-45deg);

        @media (max-width: 568px) {
          border-width: 0 2px 2px 0;
          padding: 3px;
        }
      }

      &.disabled {
        &::before {
          border-color: $gray;
        }

        &:hover {
          &::before {
            border-color: $gray;
          }
        }
      }

      &:hover {
        background: $primary;

        &::before {
          border-color: $white;
        }
      }

      i {
        display: none;
      }
    }

    .owl-prev {
      height: 54px;
      width: 54px;
      border-radius: 50%;
      background: $gray2;
      color: #fff;
      margin-left: 15px;
      text-align: center;
      font-size: 24px;
      line-height: normal;
      transition: 0.3s;
      padding: 0;
      margin: 0 0 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 556px) {
        height: 48px;
        width: 48px;
      }

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 56%;
        border: solid $gray;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: translate(-50%, -50%) rotate(135deg);

        @media (max-width: 568px) {
          border-width: 0 2px 2px 0;
          padding: 3px;
        }
      }

      &.disabled {
        &::before {
          border-color: $gray;
        }

        &:hover {
          &::before {
            border-color: $gray;
          }
        }
      }

      &:hover {
        background: $primary;

        &::before {
          border-color: $white;
        }
      }

      i {
        display: none;
      }
    }
  }

  .owl-dots {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -70px;    
    .owl-dot {
      width: 100%;
      max-width: 10%;
      height: 8px;
      border-bottom: 4px solid #d2d2d2;
      @media screen and (max-width: 575px) {
        border-bottom: 1px solid #d2d2d2;
        background-color: transparent;
      }

      span {
        display: none;
      }
    }

    .owl-dot.active {
      background: #38573c;
      border-bottom: 4px solid #38573c;
    }
  }
}

//how-slider
.review-card {
  .owl-carousel {
    padding: 0px;
  }

  .owl-nav {
    display: flex;
    position: absolute;
    right: 0;
    top: -220px;

    @media screen and (max-width: 1199px) {
      right: 0;
      top: -170px;
    }

    @media screen and (max-width: 991px) {
      top: 0px;
      right: 32px;
      position: relative;
      display: flex;
      float: right;
    }

    @media screen and (max-width: 557px) {
      right: 32px;
    }

    .owl-next {
      height: 54px;
      width: 54px;
      border-radius: 50%;
      background: $lightPrimary;
      color: #fff;
      margin-left: 15px;
      text-align: center;
      font-size: 24px;
      line-height: normal;
      transition: 0.3s;
      padding: 0;
      margin: 0 0 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 556px) {
        height: 48px;
        width: 48px;
      }

      &.disabled {
        &::before {
          border-color: $gray;
        }
      }

      &::before {
        position: absolute;
        content: "";
        top: 52%;
        left: 46%;
        border: solid $white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:hover {
        background-color: $lightPrimary;
      }

      i {
        display: none;
      }
    }

    .owl-prev {
      height: 54px;
      width: 54px;
      border-radius: 50%;
      background: $lightPrimary;
      color: #fff;
      margin-left: 15px;
      text-align: center;
      font-size: 24px;
      line-height: normal;
      transition: 0.3s;
      padding: 0;
      margin: 0 0 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 556px) {
        height: 48px;
        width: 48px;
      }

      &.disabled {
        &::before {
          border-color: $gray;
        }
      }

      &:hover {
        background: $lightPrimary;
        color: #ffffff;
      }

      i {
        display: none;
      }

      &::before {
        position: absolute;
        content: "";
        top: 52%;
        left: 52%;
        border: solid $white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
  }

  .owl-dots {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: -45px;

    .owl-dot {
      // background: #ededed;
      width: 100px;
      height: 8px;
      border-bottom: 4px solid #d2d2d2;

      span {
        display: none;
      }
    }

    .owl-dot.active {
      background: #38573c;
      border-bottom: 4px solid #38573c;
    }
  }
}

.progress_slider {
  @media (max-width: 767px) {
    margin-right: -200px;
  }

  @media (max-width: 375px) {
    margin-right: -240px;
  }
}

.single-how-img {
  position: relative;
}

.owl-theme .owl-nav .disabled,
button.disabled {
  background-color: #ededed;
  color: #c8c8c8;
  position: relative;
  opacity: 0.5;

  &:hover {
    background-color: #ededed;
    color: #c8c8c8;
  }
}

.how-meta {
  position: absolute;
  top: 24px;
  left: 20px;
  padding: 9px 14px 8px 14px;
  background: #ffffff;
  font-family: "EffraMedium", sans-serif;
  color: #536c73;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;

  @media screen and(max-width: 761px) {
    top: 12px;
    left: 12px;
  }

  img {
    display: inline-block !important;
    width: auto !important;
  }

  span {
    margin-left: 7px;
    margin-top: 3px;
  }
}

.how-slider-item {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.how-slider-inner-wrap {
  flex: 1;
}

.single-how-content {
  padding: 0px 19px;
  font-family: "EffraRegular", sans-serif;
  color: #241f1f;
  font-size: 16px;
  line-height: 1.5;

  h4 {
    font-size: 26px;
    line-height: 46px;
    padding: 18px 0px;
    margin-bottom: 0px;
  }
}

.single-how-rating {
  position: relative;
  border-top: 1px solid #dedede;
  line-height: 1.8;
  padding: 8px 0 7px 20px;

  @media (max-width: 575px) {
    padding: 10px 0 10px 17px;
  }

  &:before {
    position: absolute;
    height: 17px;
    width: 17px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background: url(./assets/img/greena.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: 767px) {
      height: 16px;
      width: 16px;
    }
  }

  span {
    font-family: "futura bold";
    font-weight: bold;
    color: #536c73;

    &.greena-index-size {
      font-family: "EffraMedium", sans-serif;
      font-size: 16px;
    }
  }
}

.single-how-feachers {
  border-top: 1px solid #dedede;
  padding: 15px 0px;

  .list-inline-item {
    display: block;
  }
}

.single-how-location {
  border-top: 1px solid #dedede;
  padding: 15px 0px;
  margin: 0 16px;
  font-family: EffraRegular, sans-serif;
  color: #241f1f;
  font-size: 20px;

  @media only screen and (min-width: 768px) and (max-width: 1600px) {
    font-size: 16px;
  }
}

/* ==========================================================================
   GREENA CARD SECTION
   ========================================================================== */
.greena-card {
  padding: 120px 0px 20px;
  background: #ffffff;
}

.about-greena-card {
  margin-top: 160px;
  padding: 180px 0;
  background-color: #f9f9f9;

  @media screen and (max-width: 1500px) {
    margin-top: 100px;
    padding: 100px 0;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 576px) {
    margin-top: 0px;
    padding: 34px 0 30px;
  }
}

.greena-card-image {
  img {
    max-width: 100%;
    height: auto;
  }
}

.greena-card-details {
  // padding-top: 45px;
  max-width: 813px;

  h2 {
    margin-bottom: 36px;
    font-size: 52px;
    letter-spacing: -0.6px;
  }

  .greena-card-details-desc {
    @media screen and (max-width: 1600px) {
      max-width: 570px;
    }

    @media screen and (max-width: 991px) {
      max-width: 100%;
    }

    @media (max-width: 575px) {
      max-width: 370px;
      margin: 0 auto;
    }

    p {
      margin-bottom: 20px;

      small {
        color: #707070;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.card-feacher-content {
  h2 {
    margin-bottom: 45px;
  }
}

.single-cardf {
  padding: 15px 0px;

  img {
    margin-bottom: 24px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 14px;
  }

  p {
    font-size: 24px;
    line-height: 1.4;
  }
}

.card-feacher-left {
  position: relative;
  background: url(./assets/img/cardbg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80%;
  margin-right: 85px;

  &:before {
    position: absolute;
    height: 86px;
    width: 86px;
    left: 55%;
    top: 7%;
    content: "";
    background: url(./assets/img/circle1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &:after {
    position: absolute;
    height: 56px;
    width: 56px;
    left: 35%;
    bottom: 8%;
    content: "";
    background: url(./assets/img/circle2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.cardfi2 {
  padding-top: 165px;
}

a.signup-cta {
  position: relative;
  display: block;
  padding: 35px 100px 35px 25px;
  font-family: "EffraMedium", sans-serif;
  background: #f9f9f9;
  font-size: 44px;
  line-height: 54px;
  color: #241f1f;

  span {
    display: block;
    font-family: "Futura", sans-serif;
    color: #a2a2a2;
    font-size: 20px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 50px;
    font-family: "bootstrap-icons" !important;
    content: "\f134";
    height: 36px;
    width: 36px;
    margin-top: -18px;
    color: #a2a2a2;
    font-size: 36px;
  }

  &:hover {
    &::after {
      content: "\f133";
      color: #536c73;
    }
  }
}

/* ==========================================================================
   FOOTER SECTION
   ========================================================================== */

/* ==========================================================================
   Responsive
   ========================================================================== */

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
}

/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

html,
body {
  // height: 100%;
  scroll-behavior: smooth;
}

.custom-tooltip {
  &.tooltip {
    opacity: 1;

    .arrow {
      position: absolute;
      width: 40px;
      z-index: 2;
      background-image: url(./assets/img/drop_arrow.svg);
      height: 40px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.bs-tooltip-bottom {
      margin-top: 20px;

      .arrow {
        top: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    &.bs-tooltip-right {
      margin-left: 23px;

      .arrow {
        top: 50%;
        left: -21px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    &.bs-tooltip-left {
      margin-right: 23px;

      .arrow {
        top: 50%;
        right: -20px;
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &.bs-tooltip-top {
      margin-bottom: 10px;

      .arrow {
        bottom: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: rotate(180deg);
      }
    }

    &.bs-tooltip-bottom-left,
    &.bs-tooltip-top-left {
      margin-left: -22px;

      .arrow {
        left: 10px;
        margin: 0;
      }
    }

    &.bs-tooltip-bottom-right,
    &.bs-tooltip-top-right {
      margin-left: 22px;

      .arrow {
        right: 10px;
        left: auto;
        margin: 0;
      }
    }

    .tooltip-inner {
      background-color: #fff;
      color: #1b1919;
      text-align: left;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      max-width: none;
      padding: 16px 14px;
      border-radius: 0;

      .tooltip-wrap {
        display: flex;
        max-width: 378px;

        @media screen and (max-width: 767px) {
          max-width: 291px;
        }

        // @media screen and (max-width: 575px) {
        //   max-width: 240px;
        // }
        @media screen and (max-width: 480px) {
          max-width: 189px;
        }

        > i {
          margin-right: 10px;
          margin-top: 4px;

          @media (max-width: 767px) {
            margin-top: 0;
          }
        }
        &.home-tooltip {
          .tooltip-content {
            p {
              font-size: 20px;
              line-height: 32px;
              letter-spacing: -0.2px;
              font-family: "EffraRegular", sans-serif;
              @media screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
        &.personalised-tooltip {
          max-width: 320px;
          flex-direction: column;
          padding: 4px 6px;
          @media (max-width: 375px) {
            max-width: 240px;
            padding: 0;
          }
          i {
            margin: 0 0 8px;
            svg {
              height: 24px;
              width: 24px;
              @media (max-width: 375px) {
                height: 20px;
                width: 20px;
              }
            }
          }
          .tooltip-content {
            p {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 20px;
              font-family: "Effra";
              @media (max-width: 574px) {
                overflow-y: unset;
              }
              &:last-child {
                margin: 0;
              }
              @media (max-width: 375px) {
                line-height: 18px;
              }
            }
            ul {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 20px;
              padding-left: 20px;
              * {
                font-family: "Effra";
              }
              li {
                list-style-type: disc;
              }
            }
            a {
              margin: 0;
              color: $primary;
            }
          }
        }
        .tooltip-content {
          p {
            line-height: 32px;
            letter-spacing: -0.2px;
            font-family: "EffraRegular", sans-serif;

            @media screen and (max-width: 767px) {
              line-height: 20px;
              font-size: 16px;
            }

            @media screen and (max-width: 480px) {
              margin-bottom: 5px;
            }

            b {
              font-family: $btn-font-family;
              font-weight: 400;
            }
            &.home-tooltip-content {
              font-size: 18px;
            }

            &.quetionary {
              font-size: 16px;
              color: #241f1f;
              font-weight: 400;
              line-height: revert;
              font-family: "EffraRegular", sans-serif;

              p {
                @extend .quetionary;
              }

              a {
                color: #38573c;
                text-decoration: none;
                font-family: "EffraRegular", sans-serif;
                margin-top: 0;
              }

              * {
                font-family: "EffraRegular", sans-serif;
              }
            }

            &.how-section-content {
              font-size: 18px;
              font-family: "EffraRegular", sans-serif;

              @media screen and (max-width: 767px) {
                line-height: 20px;
                font-size: 16px;
              }
            }
            &.tooltip-detail{
              max-height: 500px;
              overflow-y: auto;
              @media screen and (max-width: 574px) {
                max-height: 300px;
              }
              ol, ul {
                padding-left: 1.5rem;
                font-family: "Effra";
                li {
                  font-family: "Effra";
                  list-style: inherit;
                  margin-top: 8px;
                  span{
                    font-family: "Effra";
                  }
                }
              }
            }
          }

          a {
            color: #1e88e5;
            text-decoration: underline;
            display: inline-block;
            margin-top: 18px;
            &.home-tooltip-link {
              font-size: 18px;
              font-family: $btn-font-family;
            }
          }

          &.vendors-content {
            p {
              font-size: 18px;
              font-family: "Effra";
            }
            b {
              font-family: "Effra Medium";
              font-weight: 400;
            }
            a {
              font-family: $btn-font-family;
            }
          }
        }
      }
    }
  }
}

.contact_popup.p-dialog,
.p-dialog-mask.p-component-overlay {
  background-color: rgba(29, 24, 24, 0.64) !important;
}

.contact_popup {
  .p-dialog {
    font-family: "EffraRegular", sans-serif;
    overflow-y: auto;

    @media (max-width: 576px) {
      padding: 10px 18px;
      box-shadow: none;
      max-height: 100%;
    }

    .p-dialog-header {
      padding: 32px 40px 17px;
      border-radius: 0;
      align-items: flex-start;

      @media (max-width: 576px) {
        padding: 16px 14px 14px;
      }

      .p-dialog-title {
        color: #241f1f;
        font-size: 24px;
        font-family: "EffraRegular", sans-serif;

        @media (max-width: 576px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }

    .p-dialog-content {
      padding: 0px 40px 34px;
      overflow-y: visible;
      height: 100%;

      @media (max-width: 576px) {
        padding: 0 14px 20px;
      }
    }
  }
}

.contact_signup_form {
  form {
    border-top: 1px solid #d3d2d2;

    .form-group {
      padding-bottom: 0;

      label {
        font-size: 20px;
        color: #241f1f;
        margin: 20px 0 14px;
        font-family: "EffraRegular", sans-serif;

        @media (max-width: 576px) {
          font-size: 16px;
          margin: 16px 0 12px;
        }
      }

      .custom_phone_drop {
        .num_selecter {
          background: #f0f2f2;
          justify-content: flex-start;
          width: 96px;

          @media (max-width: 1600px) {
            width: 86px;
          }

          @media (max-width: 1600px) {
            width: 75px;
          }

          &::after {
            right: 10px;
          }
        }
      }

      .comman_anchor {
        margin-top: 26px;

        @media (max-width: 576px) {
          margin-top: 2px;
          padding: 5px 19px;
        }
      }
    }
  }
}

.option_dropdown {
  .option_selector {
    width: 100%;
    background-color: #f6f6f6;
    text-align: left;
    height: 56px;
    // border: 0;
    padding: 11px 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ededed;

    &.show {
      &::after {
        transform: rotate(180deg);
        right: 4px;
      }

      span {
        color: #241f1f;
      }
    }

    span {
      font-size: 16px;
      font-family: "EffraRegular", sans-serif;

      @media (max-width: 1440px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 16px;
        padding-right: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }

    .dropbutton {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      border: 0;
      background: transparent;
    }

    input {
      font-size: 18px;
      height: auto;
      padding: 0;
      border-bottom: 0;

      &:focus {
        outline: none;
        border-bottom: 0;
      }

      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }

    .dropdown-lable {
      font-size: 12px;
    }

    i {
      svg {
        height: 16px;
        width: 16px;
        transform: rotate(90deg);
      }
    }

    &.guest {
      border-bottom: 2px solid $gray2;
    }

    &.invalid-error {
      background-color: #fdf0f1;
      border-color: #e0293b;

      ::ng-deep i {
        svg path {
          fill: #e0293b;
        }
      }
    }
  }

  .option_menu {
    background-color: #f7f8f8;
    padding: 10px 14px 4px;
    display: block;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    border: 0 !important;

    // position: relative !important;
    &.show {
      top: 0 !important;
      border: 0 !important;
      transform: translate(0) !important;
      visibility: visible;
      opacity: 1;
    }

    li {
      position: relative;
      padding-bottom: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-right: 20px;

      &::after {
        position: absolute;
        content: "";
        background: url(assets/img/green_check.svg) no-repeat right;
        background-size: contain;
        width: 25px;
        height: 20px;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease 0s;
      }

      &.selected {
        &::after {
          opacity: 1;
          visibility: visible;
        }

        span {
          color: #8ea986;
        }
      }

      span {
        font-size: 18px;
        color: #241f1f;
        display: block;

        @media screen and (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }
}

.p-relative {
  position: relative !important;
}

.spinner-custom {
  width: 20px;
  height: 20px;
  border: 3px solid;
  margin-right: 5px;
  border-right-color: transparent;
}

.anchor-disable {
  opacity: 0.5;
  pointer-events: none;
}

// Spinner

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #8ea986;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.option_name,
.option_name p {
  font-size: 14px !important;
  line-height: normal;
  line-height: 22px;
  // @media screen and (max-width: 576px) {
  //   font-size: 16px !important;
  // }
}

.width-max-content {
  width: max-content;
}

.radios-btn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & + .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 30px;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: #42555b;
      display: none;
    }
  }
}

.invalid-controls {
  position: absolute;
  margin-top: 0;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.invalid-error {
  .form-control {
    background-color: #fdf0f1;
    border-color: #e0293b;

    &::-webkit-input-placeholder {
      color: $dark;
    }

    &::-moz-placeholder {
      color: $dark;
    }

    &:-ms-input-placeholder {
      color: $dark;
    }

    &:-moz-placeholder {
      color: $dark;
    }
  }

  .show-hide-password {
    display: none;
  }
}

.form-check {
  position: relative;
  font-size: 14px;

  &.invalid-error {
    padding-bottom: 20px;

    .invalid-controls {
      bottom: 0;
    }
  }
}

.input-wrap {
  position: relative;

  i {
    position: absolute;
    right: 15px;
    top: 8px;

    @media screen and (max-width: 575px) {
      top: 5px;
    }
  }
}

.show-hide-password {
  cursor: pointer;

  i {
    &:last-child {
      display: none;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &.show {
    i {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}

.ng-country-select-dropdown {
  &.ng-select.ng-select-single {
    &.ng-select-opened {
      & > .ng-select-container {
        .ng-arrow {
          transform: rotate(180deg);
        }
      }
    }

    .ng-dropdown-panel.ng-select-bottom {
      margin-top: 8px;
      border: 0;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      padding: 10px 16px;
      border-radius: 0;

      .ng-option {
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px solid $gray2;
        }

        &.ng-option-selected {
          &::before {
            content: "";
            position: absolute;
            right: 8px;
            top: 22px;
            width: 7px;
            height: 14px;
            border-top: 2px solid #38573c;
            border-left: 2px solid #38573c;
            transform: rotate(-128deg);
          }
        }
      }
    }

    &.location-dropdown {
      .ng-dropdown-panel.ng-select-bottom {
        padding: 0px 16px;

        .ng-option {
          padding: 16px 0;
        }
      }
    }

    &[ng-reflect-model="Select country"] {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            .ng-value-label {
              color: #bdbcbc;
            }
          }
        }
      }
    }

    &.invalid-error {
      padding-bottom: 24px;

      .ng-select-container {
        background-color: #fdf0f1;
        border-color: #e0293b;

        .ng-value-container {
          .ng-placeholder {
            color: $dark;
          }
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            background-image: url(assets/img/arrowDownRed.svg);
          }
        }
      }
    }

    .ng-select-container {
      font-size: 16px;
      height: 56px;
      padding: 18px 14px;
      border: 0;
      border-bottom: 2px solid $gray2;
      background-color: $light;
      border-radius: 4px 4px 0 0;
      box-shadow: none !important;

      @media screen and (max-width: 575px) {
        height: 48px;
        padding: 12px 14px;
        font-size: 14px;
      }

      .ng-value-container {
        padding: 0;

        .ng-placeholder {
          display: block;
          color: #808080;
        }

        .ng-value {
          height: 100%;
          white-space: normal;
          overflow: initial;

          .ng-value-label {
            display: flex;
            align-items: center;
            color: #21252a;

            img {
              width: 30px;
              height: 20px;
              margin-right: 12px;
              object-fit: cover;

              @media (max-width: 1600px) {
                width: 24px;
              }
            }
          }
        }

        .ng-input {
          padding: 0;
          top: 0;
          width: calc(100% - 70px);

          input {
            box-sizing: border-box;
            height: 100%;
            caret-color: transparent;
            padding: 18px;

            @media screen and (max-width: 575px) {
              padding: 13px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      .ng-clear-wrapper {
        top: initial;
        // position: absolute;
        // right: 40px;
        z-index: 100;
        // transform: translateY(-50%);
        width: 20px;
        height: 20px;
        display: block;
        line-height: 31px;

        span {
          font-size: 27px;
          line-height: 22px;
        }
      }

      .ng-arrow-wrapper {
        height: 25px;

        .ng-arrow {
          background-image: url(assets/img/arrowDown.svg);
          border: 0;
          width: 12px;
          height: 8px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;
          top: 0;
          transition: all 0.1s ease-in;
          // border-bottom: 2px solid #1b1919;
          // width: 8px;
          // height: 8px;
          // transform: rotate(45deg);
          // border-right: 2px solid #1b1919;
          // border-left: 0;
          // border-top: 0;
          top: 0;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          font-size: 18px;
          color: #241f1f;
          background-color: #fff;

          @media (max-width: 1600px) {
            font-size: 16px;
          }

          img {
            width: 27px;
            height: 15px;
            margin-right: 7px;
            object-fit: cover;

            @media (max-width: 1600px) {
              width: 24px;
            }
          }
        }
      }
    }
  }

  &.selected-value,
  &.ng-select-filtered {
    &.ng-select {
      &.ng-select-single {
        .ng-value-container {
          padding: 0;

          .ng-placeholder {
            display: none;
          }
        }
      }
    }
  }
}

.glx-common-title {
  font-family: "Futura";
  font-size: 40px;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 52px;

  @media screen and (max-width: 1600px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (max-width: 767px) {
    font-size: 22px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.ng-select.ng-select.ng-select-single.ng-select-filtered:not(.ng-select-opened)
  .ng-select-container.ng-has-value
  .ng-value-container
  .ng-value {
  visibility: visible;
}

.separate-link-image {
  width: 100vw;
  height: 100vh;
}

.image-container {
  max-width: 650px;
  margin: 0 auto;
}

.p-toast {
  max-width: calc(100vw - 40px);

  .p-toast-message {
    .p-toast-message-content {
      align-items: center;
      .p-toast-message-icon {
        font-size: 22px;
      }

      .p-toast-detail {
        margin-top: 0;
        font-family: "Effra";
      }
    }
  }
}

.ng-country-select-dropdown.ng-select.ng-select-single.ng-select-opened > .ng-select-container .ng-arrow {
  // transform: rotate(225deg);
  transform: rotate(180deg);
  transition: all 0.1s ease-in;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// custom dropdown
.option {
  box-shadow: 0 0 6px $boxshadow;
  margin-top: 8px;
}

.ul-wrapper {
  padding: 16px 20px 4px 16px;
  border-bottom: 1px solid $gray2;

  ul li {
    cursor: pointer;
  }

  @media (max-width: 575px) {
    padding: 12px 12px 0px 12px;
  }

  .select-heading {
    font-size: 18px;
    line-height: 24px;
    color: $dark;
    margin-bottom: 0;
    &.guest-dash-select-heading {
      font-family: $btn-font-family;
    }

    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  li {
    padding: 12px 0;
    border-bottom: 1px dashed $gray2;
    font-size: 14px;
    line-height: 18px;
    color: $dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Effra";

    @media (max-width: 575px) {
      padding: 8px 0;
      font-size: 12px;
      line-height: 16px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .icon-wrapper {
      position: relative;
      height: 16px;
      width: 16px;
      margin-left: 6px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 14px;
        border-top: 2px solid #38573c;
        border-left: 2px solid #38573c;
        transform: rotate(-135deg);
      }
    }
  }
}

.guest-category {
  .guest-card-wrapper {
    .score-details-wrapper {
      margin-top: 16px;
      padding: 16px 16px 12px;
      background-color: #bfd6c133;

      @media (max-width: 375px) {
        padding: 10px;
      }
      .eco-details {
        li {
          padding: 14px 0;
          border-bottom: 1px solid $gray2;
          font-size: 15px;
          line-height: 20px;
          font-family: "Effra";
          @media (max-width: 386px) {
            font-size: 12px;
            line-height: 16px;
            padding: 12px 0;
          }
          i {
            margin-right: 12px;
            line-height: normal;
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            ::ng-deep svg {
              path {
                fill: #38573c;
              }
            }
            @media (max-width: 386px) {
              margin-right: 8px;
              height: 16px;
              width: 16px;
            }
            &.info_icon {
              height: 16px;
              width: 16px;
              display: inline-block;
              cursor: pointer;
              ::ng-deep svg {
                @media (max-width: 386px) {
                  height: 12px;
                  width: 12px;
                  margin-left: 0;
                }
                path {
                  fill: $gray4 !important;
                }
              }
            }
          }
          .score-img-wrapper {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: start;
            margin-right: 12px;
            img {
              width: 100%;
              object-fit: contain;
            }
            i {
              @media (max-width: 386px) {
                margin-left: 0;
                margin-right: 0;
              }
            }
            @media (max-width: 386px) {
              width: auto;
              margin-right: 4px;
            }
          }
        }
      }
      .score-details {
        padding-bottom: 12px;
        border-bottom: 1px solid $gray2;

        h4 {
          margin-bottom: 0;
          color: $primary;
          font-size: 28px;
          line-height: 36px;

          @media (max-width: 386px) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        i {
          cursor: pointer;
          line-height: 10px;
          margin-left: 8px;

          svg {
            height: 20px;
            width: 20px;

            @media (max-width: 386px) {
              height: 16px;
              width: 16px;
            }
          }
          @media (max-width: 386px) {
            margin-left: 4px;
          }
          &.info_icon {
            @media (max-width: 386px) {
              margin-left: 0px;
            }
            svg {
              @media (max-width: 386px) {
                height: 12px;
                width: 12px;
              }
            }
          }
        }
        .pros-content {
          font-family: "Effra";
        }
      }

      .line-wrapper {
        line-height: 0;
        margin-top: 12px;
        text-align: center;

        .view-more-btn {
          font-size: 12px;
          line-height: 16px;
          color: $dark;

          @media (max-width: 375px) {
            font-size: 12px;
            line-height: 16px;
          }

          i {
            margin-left: 4px;

            svg {
              height: 14px;
              width: 14px;
              transform: rotate(90deg);
            }
          }

          &.more {
            i {
              svg {
                transform: rotate(-90deg);
              }
            }
          }
        }

        @media (max-width: 375px) {
          margin-top: 8px;
        }
      }
    }
    &:first-child {
      .score-details-wrapper {
        .eco-details {
          .score-details {
            .pros-content {
              @media (max-width: 386px) {
                max-width: 237px;
              }
            }
          }
        }
      }
    }
    &:nth-child(2) {
      .score-details-wrapper {
        background-color: #dce4e820;
        .eco-details {
          .score-details {
            border-bottom: 1px solid $gray2;
            .pros-content {
              @media (max-width: 386px) {
                max-width: 220px;
              }
            }
            h4 {
              color: #7293a1;
            }
          }
        }
      }
    }

    &:last-child {
      .score-details-wrapper {
        background-color: #d6e0d320;

        .score-details {
          border-bottom: 1px solid $gray2;

          h4 {
            color: #8ea986;
          }
        }
      }
    }
  }
}

.privacy_policy_section {
  padding: 40px 0 100px;
  @media screen and (max-width: 575px) {
    padding: 20px 0 40px;
  }
}

.policy_header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 40px 44px;
  @media screen and (max-width: 1440px) {
    padding: 25px 29px;
  }
  @media screen and (max-width: 575px) {
    padding: 14px 16px;
  }
  .left_wrap {
    .privacy_title {
      font-family: "Futura", sans-serif;
      font-size: 56px;
      line-height: 72px;
      color: #536c73;
      margin-bottom: 0;
      @media screen and (max-width: 1440px) {
        font-size: 38px;
        line-height: 56px;
      }
      @media screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 46px;
      }
      @media screen and (max-width: 575px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
  .right_wrap {
    @media screen and (max-width: 575px) {
      display: none;
    }
    .icon_list {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-left: 30px;
        @media screen and (max-width: 991px) {
          margin-left: 20px;
        }
        @media screen and (max-width: 767px) {
          margin-left: 10px;
        }
        a {
          &.active {
            img {
              opacity: 1;
            }
          }
          img {
            opacity: 0.26;
            @media screen and (max-width: 1440px) {
              width: 30px;
              height: 30px;
            }
            @media screen and (max-width: 767px) {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
}

.policy-content-area {
  margin-top: 44px;
  padding: 60px;
  border: 1px solid #d3d2d2;
  @media screen and (max-width: 1440px) {
    padding: 50px;
  }
  @media screen and (max-width: 1199px) {
    padding: 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 16px;
    border: 0;
  }

  .content-area-inner {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
    * {
      font-family: "EffraMedium", sans-serif;
    }
  }
  .content-left-wrap {
    max-width: 250px;
    width: 100%;
    border-right: 1px solid #d3d2d2;
    padding-right: 15px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      border-right: 0;
      padding-right: 0;
    }

    .content-list-dropdown {
      position: sticky;
      top: 20px;
      &.show {
        box-shadow: 0px 2px 14px #00000024;
        .table-content-list {
          display: block;
          box-shadow: 0px 8px 14px #00000024;
        }
        .content-list-title-btn {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .content-list-title-btn {
        font-family: "EffraRegular", sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: #a2a2a2;
        margin-bottom: 9px;
        position: relative;
        &::after {
          @media screen and (max-width: 767px) {
            position: absolute;
            content: "";
            top: 50%;
            transform: translateY(-50%);
            right: 3%;
            width: 24px;
            height: 24px;
            background: url("/assets/img/svg/gray_dropdown_icon.svg") no-repeat center;
            background-size: contain;
          }
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 16px;
          padding: 10px 40px 10px 14px;
          border: 1px solid #d8d7d7;
          margin-bottom: 0;
          cursor: pointer;
        }
      }
      .table-content-list {
        padding: 0;
        margin: 0;
        list-style: none;
        background-color: #fff;
        @media screen and (max-width: 767px) {
          display: none;
          padding: 16px 16px 3px;
          border-top: 0 !important;
          border: 1px solid #d8d7d7;
          position: absolute;
          width: 100%;
          right: 0;
          left: 0;
          z-index: 1;
        }
        li {
          padding: 7px 0;
          line-height: normal;
          @media screen and (max-width: 767px) {
            padding: 0 0 17px;
          }
          a {
            font-size: 16px;
            line-height: 19px;
            color: #a2a2a2;
            display: flex;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            &.active {
              color: #2579f8;
            }
            h6 {
              display: table;
              ::ng-deep span {
                display: table-cell;
                padding-right: 3px;
              }
            }
          }
        }
      }
    }
  }
  .content-right-wrap {
    flex: 1;
    padding-left: 60px;
    width: 100%;
    word-break: break-word;
    @media screen and (max-width: 1440px) {
      padding-left: 50px;
    }
    @media screen and (max-width: 1199px) {
      padding-left: 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 16px 20px 16px;
      border: 1px solid #d3d2d2;
      margin-top: 14px;
    }
    .content-tab {
      padding-top: 20px;
      .content-main-heading {
        font-family: "EffraMedium", sans-serif;
        font-size: 40px;
        line-height: 40px;
        color: #241f1f;
        margin-bottom: 20px;
        @media screen and (max-width: 1440px) {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 575px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
      ::ng-deep strong {
        font-family: "EffraMedium";
      }
      .content-sub-heading {
        font-family: "EffraMedium", sans-serif;
        font-size: 24px;
        line-height: 24px;
        color: #241f1f;
        @media screen and (max-width: 1440px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #241f1f;
        margin-bottom: 25px;
        @media screen and (max-width: 1440px) {
          margin-bottom: 18px;
        }
      }
    }
  }
}
.calendy-part{
  margin: 120px auto;
  border: 1px solid #EDEDED;
  max-width: 956px;
  width: 100%;
  .calendly-icon{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 991px) {
      margin-top: 32px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
  }
  .calendy-part-title{
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    padding: 24px 196px 0px;
    margin-bottom: 40px;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 30px;
      padding: 16px 16px 0px;
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
      padding: 16px 16px 24px;
    }
  }
  &.home-calendy{
    max-width: 100%;
    margin: 0;
    margin-bottom: 84px;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
}
